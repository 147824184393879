<template>
    <div class="main">
        <div class="body-content">
            <h1>Upload Entry</h1>
            <div class="form-group">
                <div class="form-item">
                    <label for="partyname">Party *</label>
                    <v-select :clearable="false" tabindex="0" placeholder="Select Party Name" id="partyname"
                        v-model="newContainer.partyName" :options="party" label="short_name"
                        :reduce="party => party.autoid" selectOnTab="True" />
                </div>
                <div class="form-item">
                    <label for="vcnname">VCN No *</label>
                    <v-select :clearable="false" id="vcnname" v-model="newContainer.vcnid" :options="vcns"
                        @validate-on-blur="FillVCNDetails" label="vcnName" :reduce="vcn => vcn.vcnID"
                        selectOnTab="True" />
                </div>
                <div class="form-item">
                    <label for="vesselName">Vessel *</label>
                    <v-select :clearable="false" id="vesselName" placeholder="Select Vessel"
                        v-model="newContainer.vesselID" ref="vesselName" :options="vessel" label="Name"
                        :reduce="vessel => vessel.Vesselid" selectOnTab="True" />
                </div>

                <div class="form-item">
                    <label for="voyageName">Voyage *</label>
                    <v-select :clearable="false" id="voyageName" v-model="newContainer.VoyId" :options="voyage"
                        label="Name" :reduce="voyage => voyage.Voyageid" selectOnTab="True" />
                </div>
                <div class="form-item">
                    <label for="location">Location:</label>
                    <v-select :clearable="false" id="location" v-model="newContainer.locID" :options="location"
                        label="Name" :reduce="location => location.locationid" selectOnTab="True" />
                </div>
                <td class="form-item">
                    <label for="port">Transhipment:</label>
                    <v-select id="port" :clearable="false" v-model="newContainer.pod" :options="ports" label="PortCode"
                        :reduce="port => port.portid" placeholder="Select POD" selectOnTab="True" />
                </td>
            </div>
            <div class="form-group">
                <div class="form-item">
                    <label for="fileAdd">File:</label>
                    <input type="file" id="file" @change="handleFileUpload" class="custom-file-input">
                </div>
                <div class="form-item">
                    <button type="button" class="excel-button" @click="uploadFiles">Convert</button>
                </div>
            </div>
            <hr />
            <h1>Generate PDF</h1>
            <div class="form-group">
                <div class="form-item">
                    <label for="oldcoprarno">Old Coprar No *</label>
                    <input id="oldcoprarno" class="field-p-100" v-model="oldcoprarno" />
                </div>
                <div class="form-item">                    
                    <label for="newcoprarno">New Coprar No *</label>
                    <input id="newcoprarno" class="field-p-100" v-model="newcoprarno" />
                </div>
                <div class="form-item">
                    <button type="button" class="excel-button" @click="generatepdf">Generate PDF</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';
//import { part } from 'core-js/core/function';
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { useToast } from 'vue-toastification';
import { saveAs } from 'file-saver';
import * as cs from '../assets/js/commonscript.js'

export default {
    components: {
        VSelect,
    },
    data() {
        return {
            transactions: [],
            party: JSON.parse(localStorage.getItem('party')),
            vessel: JSON.parse(localStorage.getItem('vessel')) || [],
            voyage: JSON.parse(localStorage.getItem('voyage')) || [],
            location: JSON.parse(localStorage.getItem('Area')) || [],
            ports: JSON.parse(localStorage.getItem('ports')) || [],
            vcns: [],
            oldcoprarno:"",
            newcoprarno:"",
            newContainer: {
                partyName: 0,
                applicantID: 0,
                accountID: 0,
                vesselID: 0,
                vesselname: '',
                consign: '',
                vcnid: 0,
                VoyId: 0,
                voyage: '',
                pod: 128,
                podcode: '',
                locID: 0,
                file: null,
            },
            htmlContent: null,
        }
    },
    created() {
        this.fetchVcnList();
    },
    methods: {
        async fetchVcnList() {
            const token = localStorage.getItem('access_token');
            try {
                const config = {
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const response = await axios.get('/active_vcn_list/', config);
                this.vcns = response.data[2];
            } catch (error) {
                console.error('Error fetching VCN list:', error);
                this.error = 'Error fetching VCN list. Check console for details.';
            }
        },
        FillVCNDetails() {
            alert("Hi");
            //const vcnid = this.newContainer.vcnid
            const selvcn = this.vcns.find(vcn => vcn.vcnID === this.newContainer.vcnid);
            console.log(selvcn)
            if (selvcn.vcnID !== 0) {
                if (selvcn) {
                    this.newContainer.vesselID = selvcn.vesselID
                    this.newContainer.VoyId = selvcn.export_voyage
                    this.newContainer.voyage = selvcn.export_voyageName
                    this.newContainer.locID = selvcn.MlocID
                }
            }
        },
        updateApplicantAccountIds(value) {
            const AppAcc = cs.GetAppAccFromParty(value)
            this.newContainer.applicantID = AppAcc.applicantid
            this.newContainer.accountID = AppAcc.accountid
        },
        handleFileUpload(event) {
            this.newContainer.file = event.target.files[0];
        },
        async uploadFiles() {
            this.updateApplicantAccountIds(this.newContainer.partyName)

            if (this.newContainer.applicantID == 0) {
                useToast().error("Select party");
                return;
            }
            const selvcn = this.vcns.find(vcn => vcn.vcnID === this.newContainer.vcnid);
            console.log(selvcn)
            if (selvcn.vcnID !== 0) {
                if (selvcn) {
                    this.newContainer.vesselID = selvcn.vesselID
                    this.newContainer.VoyId = selvcn.export_voyage
                    this.newContainer.voyage = selvcn.export_voyageName
                    this.newContainer.locID = selvcn.MlocID
                }
            } else {
                useToast().error("Select VCN");
                return;
            }
            const selvessel = this.vessel.find(vessel => vessel.Vesselid === this.newContainer.vesselID);
            if (selvessel.Vesselid !== 0) {
                if (selvessel) {
                    this.newContainer.consign = selvessel.txtColsign
                    this.newContainer.vesselname = selvessel.Name
                }
            } else {
                useToast().error("Select vessel");
                return;
            }
            const selport = this.ports.find(port => port.portid === this.newContainer.pod);
            if (selport.portid !== 0) {
                if (selport) {
                    this.newContainer.podcode = selport.PortCode
                }
            } else {
                useToast().error("Select Transipment Port");
                return;
            }

            if (this.newContainer.locID == 0) {
                useToast().error("Select location");
                return;
            }
            if (this.newContainer.file == null) {
                useToast().error("Select file");
                return;
            }
            if (this.newContainer.consign == null) {
                useToast().error("Vessel colsign not entered");
                return;
            }

            console.log(this.newContainer);
            const token = localStorage.getItem('access_token');
            const config = {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include the token in the headers
                    'Content-Type': 'multipart/form-data'
                },
            };
            //return;
            const data = new FormData();
            data.append('file', this.newContainer.file);
            const response = await axios.post('/convert_cartin?applicant=' + this.newContainer.applicantID + '&accountid=' + this.newContainer.accountID + '&vesselid=' + this.newContainer.vesselID + '&voyageid=' + this.newContainer.VoyId + '&loc=' + this.newContainer.locID + '&consign=' + this.newContainer.consign + '&vesselname=' + this.newContainer.vesselname + '&voyage=' + this.newContainer.voyage + '&transhipment=' + this.newContainer.podcode.trim() + '&pod=' + this.newContainer.pod, data, config, { responseType: 'blob' });
            console.log(response)
            if (response.data[0] === 0) {
                useToast().error(response.data[1]);
            } else {
                useToast().success("File Downloaded");
                const blob = new Blob([response.data]);
                saveAs(blob, "COPRARE.txt");
                //this.htmlContent = response.data;
                //this.resetNewContainer();
            }
        },
        async generatepdf(){
            console.log(this.oldcoprarno)
            const token = localStorage.getItem('access_token');
            const config = {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include the token in the headers
                    'Content-Type': 'multipart/form-data'
                },
            };
            this.updateApplicantAccountIds(this.newContainer.partyName)
            const response = await axios.post('/convert_cartin_pdf?coprarno=' + this.oldcoprarno + '&newcoprarno=' + this.newcoprarno +'&applicant=' + this.newContainer.applicantID + '&accountid=' + this.newContainer.accountID, config);
            console.log(response)
        }
    }
}

</script>
<style scoped>
/* Form group styling for form elements */
.field-p-100{
    width: 100%;
}
.form-group {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 15px;
}

.form-item {
    width: 30%;
}

select,
input[type="text"],
input[type="date"],
input[type="number"],
v-select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    background-color: #FFF;
}

.excel-button {
    background-color: #17a2b8;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}
</style>